div#tag{
    border-radius: 5px;
    background: #ffffff;
    box-shadow:  2px 2px 4px #d9d9d9,
                -2px -2px 4px #ffffff;
    text-align: center;
}
div#tag p{
    font-size: 1rem;
    color: white;
    border-radius: 0 0 5px 5px;
    padding: 2px 0 0 0;
}
div#tag #number{
    font-weight: 600;
    font-family: 'Baloo 2', cursive;
    font-size: 1.5rem;
    display: block;
    padding: 5px 0 2px 0;
}