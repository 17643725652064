@import url(https://fonts.googleapis.com/css2?family=Baloo+2:wght@600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Baloo+Chettan+2:wght@500&display=swap);
body{
    background-color: #E8EBF0;
    /* background-color: white; */

}
.header{
    background-color: white;
    text-align: center;
    /* border-bottom: solid 1px #bdbdbd; */
    margin-bottom: 1rem;
    padding: 0.3rem 0rem 0.2rem;
  box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
}
.header h1{
    
    font-weight: 600;
    font-family: 'Baloo 2', cursive;
    text-transform: uppercase;
    letter-spacing: -1px;
}
.header h1 span{
    color: red;
}

.footer{
    text-align: center;
    margin-bottom: 20px !important;
}


.heart {
    background-color: red;
    display: inline-block;
    height: 30px;
    /* margin: 0 10px; */
    position: relative;
    top: 0.6rem;
    -webkit-transform: rotate(-45deg) scale(0.4);
            transform: rotate(-45deg) scale(0.4);
    width: 30px;
  }
  
  .heart:before,
  .heart:after {
    content: "";
    background-color: red;
    border-radius: 50%;
    height: 30px;
    position: absolute;
    width: 30px;
  }
  
  .heart:before {
    top: -15px;
    left: 0;
  }
  
  .heart:after {
    left: 15px;
    top: 0;
  }
h5#title{
    font-family: 'Baloo 2', cursive;
    text-transform: uppercase;
    text-align: center;
}
p#datetime{
    font-size: 0.75rem;
    text-align: center;
}
div#tag{
    border-radius: 5px;
    background: #ffffff;
    box-shadow:  2px 2px 4px #d9d9d9,
                -2px -2px 4px #ffffff;
    text-align: center;
}
div#tag p{
    font-size: 1rem;
    color: white;
    border-radius: 0 0 5px 5px;
    padding: 2px 0 0 0;
}
div#tag #number{
    font-weight: 600;
    font-family: 'Baloo 2', cursive;
    font-size: 1.5rem;
    display: block;
    padding: 5px 0 2px 0;
}
